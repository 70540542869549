<template>
    <div>
        <fade-in-image
            class="vin-img"
            :src="item.image"
            placeholder="/img/car-placeholder.svg"
            width="80px"
            height="60px" />
    </div>
</template>

<script>
import FadeInImage from '@/components/globals/FadeInImage';

export default {
    components: {
        FadeInImage
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.vin-img {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
    transform: scale(1.2)
}
</style>